// $primary: #0080ff;
// $link-color: #3E8ED7;

$font-size-base: 1rem;
$font-family-base: 'Open Sans', sans-serif;

$h1-font-size: $font-size-base * 1.75;
$h2-font-size: $font-size-base * 1.5;
$h3-font-size: $font-size-base * 1.375;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1.125;
// $h6-font-size: $font-size-base;

$enable-cssgrid: true;

$form-range-thumb-bg: #075062;
$form-range-track-bg: #075062;
$form-range-thumb-active-bg: #053a47;
$form-range-thumb-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 132, 0.25);
$form-range-track-height: .25rem;

// breakpoints
$small: 576px;
$medium: 767px;
$large: 992px;
$x-large: 1200px;
$header-color: #CC1400;
$card-bg: #f2f3f4;

$form-label-font-weight: 600;
$form-label-font-size: 14px;

$modal-content-bg: #f4f4f4;
$modal-header-padding: 1.5rem 1.5rem 0 1.5rem;
$modal-header-border-width: 0;
$modal-inner-padding: 1.5rem;
$modal-title-line-height: 1;

$input-placeholder-color: #AAAAAA;
