@import 'variables';
@import 'vendor';

:root {
    --border-color: #ccc;
    --primary: #79aec8;
    --selected-bg: #e4e4e4;
    --body-quiet-color: #666;
    --body-loud-color: #000;
    --bs-border-color: rgb(175, 175, 175);
}

html,
body {
    width: 100%;
    height: 100%;
}

body,
.btn-link,
.avatar {
    // font-family: 'Source Sans Pro';
    // font-family: 'Montserrat', Sans-serif;
    // font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}

#body-wrapper {
    min-height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 100%;
}

a,
.link {
    // color: #222222;
    // color: #4632da;
    cursor: pointer;
    // text-decoration: none;

    &:hover:not(.btn) {
        text-decoration: underline;
    }
    &.colored {
        color: #0d6efd;
    }
}

.nav-tabs a {
    color: #495057;

    &:hover {
        text-decoration: none;
    }
}

.nav-tabs a,
.navbar-nav a,
.dropdown-menu a {
    &:hover {
        text-decoration: none;
    }
}

footer {
    color: #6e6e73;
    // z-index: 2;

    a {
        color: #a1a1a6;

        &:hover {
            color: white;
        }
    }
}

.powerhouse-logo {
    width: 200px;
    height: 33px;
    margin: 16px 0;
}

@media (min-width: $small) {
    .powerhouse-logo {
        width: 240px;
        height: 40px;
    }
}

.donortown-logo {
    width: 120px;
    height: 68.69px;
    margin: 0px 0;
}

.avatar {
    cursor: pointer;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    margin-top: 0px;
    font-weight: 500;
    object-fit: cover;
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// .nav-link {
//     font-family: 'Bebas Neue', Sans-serif;
//     letter-spacing: 3px;
// }

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

.bg-dark {
    background-color: #1d1d1f;
}

.btn:not(.btn-outline-success, .btn-outline-primary, .btn-outline-secondary) {
    // border-radius: 20px;
    border: 0;
}

.btn-wide {
    padding: 0.5rem 0.75rem;
    width: 100%;
}

// .btn-primary {
//     background: linear-gradient(90deg, #00a7fb 0, #0080ff);

//     &:hover {
//         background: linear-gradient(90deg, #009eee 0, #0079f2);
//     }
// }

.btn-light:hover {
    background-color: #e2e6ea;
}

.btn-facebook {
    background-color: #3b5997;
    border-color: #3b5997;
    color: white;
}

.btn-facebook:hover,
a .btn-facebook:hover {
    background-color: #263960;
    border-color: #263960;
    color: white;
}

.btn-twitter {
    background-color: #08a0e9;
    border-color: #08a0e9;
    color: white;
}

.btn-twitter:hover,
a .btn-twitter:hover {
    background-color: #0084b4;
    border-color: #0084b4;
    color: white;
}

.btn-share-icon {
    padding: 0.625rem 0.75rem;
    padding: 0.325rem 0.45rem;
    border-radius: 50%;
}

.input-group .btn {
    border-radius: 0.25rem;
    border: 1px solid var(--bs-border-color);
}

label:not(.form-check-label),
.label,
.form-label {
    margin-bottom: 0.25rem;
    text-transform: uppercase;
    // color: #8e8d8d;
    font-size: 14px;
    font-weight: 600;
    // letter-spacing: 1px;
}

.form-check-input {
    border-color: rgb(111, 111, 111);
}

#purchase-detail-modal-content .label {
    margin-bottom: 0;
}

.navbar {
    background-color: $header-color;
    // box-shadow: 6px 5px 30px 0px rgba(0, 0, 0, 0.12);
    font-weight: 400;
    &.navbar-donortown {
        // background-color: #27A9E0;
        // background-color: black;
        // background-color: white;
    }
    &.navbar-stage {
        // background-color: #fcd12a;
        // background-color: #c49102;
        // background-color: #ff5722;
    }
}

.nav-link {
    font-size: 0.9375rem;
    // text-transform: uppercase;
}

.nav-link.active {
    // color: #0056b3;
    // color: #222222;
    // text-decoration: underline;
}

.side-nav .nav-link {
    padding-left: 0;
    &.active {
        text-decoration: underline;
    }
}

.inline-link {
    cursor: pointer;
    text-decoration: underline;
}

.content-box {
    margin-top: 2rem;
}

[v-cloak] {
    display: none !important;
}

.fade-enter-active {
    transition: opacity 0.5s;
}

.fade-enter {
    opacity: 0;
}

.pointer {
    cursor: pointer;
}

.no-refund-text,
.fs-7 {
    font-size: 0.875rem;
}

.disable-dbl-tap-zoom {
    touch-action: manipulation;
}

input.no-spinner[type='number'] {
    -moz-appearance: textfield;
}

input.no-spinner[type='number']::-webkit-inner-spin-button,
input.no-spinner[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.form-group {
    margin-bottom: 1rem;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.multiselect__option--highlight {
    background: $header-color !important;
}

.multiselect__content-wrapper {
    z-index: 3 !important;
}

.multiselect__single {
    font-weight: 400;
}

// .multiselect--active:not(.multiselect--above) .multiselect__tags {
//     border-bottom-left-radius: initial;
// }

body.swal2-height-auto {
    height: 100% !important;
}

.widget-img {
    max-width: 200px;
    max-height: 200px;
}

.widget-img-sm {
    max-width: 100px;
    max-height: 100px;
}

#id_locations {
    list-style-type: none;
    padding-left: 0;
    label {
        input {
            font-size: 1rem;
            margin-top: 0;
        }
    }
}

@media (min-width: 992px) {
    .offset-lg-half {
        margin-left: 4.1666666667%;
    }
}

@media (min-width: 1200px) {
    .offset-xl-1-5 {
        margin-left: 12.5%;
    }
}

.card {
    background-color: $card-bg;
    border-radius: 1rem;
    padding: 1rem;
    border: 0;
}

.groups {
    a {
        color: inherit;
        text-decoration: initial;
        &:hover:not(.btn) {
            text-decoration: initial;
        }
    }
    .card {
        height: 250px;
        padding-top: 0.75rem;
    }
    .card-body {
        overflow: hidden;
    }
    .card-add {
        background-color: transparent;
        border: none;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%237d8ca3FF' stroke-width='3' stroke-dasharray='4 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 16px;
        &:hover {
            background-color: $card-bg;
            // background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%237d8ca3FF' stroke-width='4' stroke-dasharray='4 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        }
        //   h4 {
        //     color: $shadow-blue;
        //   }
    }
}

#print-container {
    display: none;
}
@media print {
    #print-container {
        display: block;
    }
    #body-wrapper,
    .modal,
    .modal-backdrop {
        display: none !important;
    }
    .page-break {
        page-break-before: always;
    }
}

.select2-container.select2-container--admin-autocomplete {
    &.select2-container {
        min-height: 36px;
    }
    &.select2-container--focus .select2-selection,
    .select2-container.select2-container--admin-autocomplete.select2-container--open .select2-selection {
        min-height: 36px;
    }
    &.select2-container--focus .select2-selection.select2-selection--single,
    .select2-container.select2-container--admin-autocomplete.select2-container--open
        .select2-selection.select2-selection--single {
        padding: 2px 0;
    }
    .select2-selection--single {
        min-height: 36px;
        padding: 2px 0;
        .select2-selection__arrow {
            top: 4px;
        }
    }
    .select2-selection--single,
    .select2-selection--multiple {
        border: var(--bs-border-width) solid var(--bs-border-color);
        border-radius: var(--bs-border-radius);
    }
}
